<template>
  <!--general layout-->
  <div class="d-flex flex-row-reverse flex-lg-row overflow">
    <WelcomeAbroad :show="firstTimeLogin"></WelcomeAbroad>

    <div><SidebarLeft /></div>

    <div class="app-content">
      <Header></Header>
      <CountDownBanner @open-subscription="openSubscription = true" />
      <Pricing class="view" v-if="openSubscription" />
      <router-view class="view" v-else :key="$route.path" />
    </div>

    <div><SidebarRight /></div>
  </div>
</template>

<script>
/* eslint-disable */
import Auth from "@/store/Auth.js";
import SidebarLeft from "@/components/SidebarLeft.vue";
import SidebarRight from "@/components/SidebarRight.vue";
import Header from "@/components/Header.vue";
import CountDownBanner from "@/components/CountDownBanner.vue";
import Pricing from "../Pages/NoPlanPages/Pricing.vue";
import WelcomeAbroad from "./WelcomeAbroad.vue";

export default {
  name: "App",
  components: {
    SidebarLeft,
    SidebarRight,
    Header,
    CountDownBanner,
    WelcomeAbroad,
    Pricing,
  },
  data() {
    return {
      plan_id: JSON.parse(localStorage.userInfo).current_plan_price?.plan_id,
      email: JSON.parse(localStorage.userInfo).email,
      openSubscription: false,
    };
  },

  computed: {
    firstTimeLogin() {
      return !localStorage.getItem("firstTimeLogin " + this.email);
    },
  },

  created() {
    Auth.getMe().then((res) => {
      const userInfo = res.data.data;
      localStorage.setItem("userInfo", JSON.stringify(userInfo));
      if (
        !userInfo || [
          undefined, "undefined", null, "null"
        ].includes(userInfo.nexlaw_credits)
      ) {
        localStorage.removeItem("accessToken");
        localStorage.removeItem("userInfo");
        this.$router.push("/login");
      }
    });
  },
};
</script>

<style scoped>
.overflow {
  overflow: hidden !important;
}
.view {
  overflow: auto;
}

/* delete? */
/* @media only screen and (max-width: 768px) {
  .floating-menu {
    display: none !important;
  }
}
.floating-menu {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  position: fixed;
  bottom: 20px;
  right: 80px;
  width: 50px;
  height: 50px;
  transform: translateY(70px);
  transition: all 250ms ease-out;
  border-radius: 50%;
  opacity: 0;
  background: -moz-linear-gradient(-45deg, #183850 0, #183850 25%, #192c46 50%, #22254c 75%, #22254c 100%);
  background: -webkit-linear-gradient(-45deg, #183850 0, #183850 25%, #192c46 50%, #22254c 75%, #22254c 100%);
  background: linear-gradient(to bottom, #094890, #094890);
  border: 2px solid #094890;

  background-repeat: no-repeat;
  background-attachment: fixed;
}

.floating-menu.chat {
  bottom: 80px;
  right: 80px;
}

.floating-menu.enter:hover {
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  opacity: 1;
}
.floating-menu.enter {
  transform: translateY(0);
  opacity: 0.6;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.12), 0px 1px 2px rgba(0, 0, 0, 0.14);
}
.floating-menu.expand {
  width: 250px;
  max-height: 400px;
  height: 400px;
  border-radius: 5px;
  cursor: auto;
  opacity: 1;
  background: linear-gradient(to bottom, #094890, #e2f2ff);
}

.floating-menu.expand .icon-robot {
  display: none;
}
.floating-menu :focus {
  outline: 0;
  box-shadow: 0 0 3pt 2pt rgba(14, 200, 121, 0.3);
  box-shadow: 0 0 1pt 1pt #094890;
}
.floating-menu button {
  background: #094890;
  border-radius: 20px;
  font-size: 12px;
  padding: 0 1rem;
  border: 0;
  color: #fff;
  text-transform: uppercase;
  cursor: pointer;
}
.floating-menu .chat {
  display: flex;
  flex-direction: column;
  position: absolute;
  opacity: 0;
  width: 1px;
  height: 1px;
  border-radius: 50%;
  transition: all 250ms ease-out;
  margin: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.floating-menu .chat.enter {
  opacity: 1;
  border-radius: 0;
  margin: 10px;
  width: auto;
  height: auto;
}
.floating-menu .chat .header {
  flex-shrink: 0;
  padding-bottom: 10px;
  display: flex;
  background: transparent;
  position: absolute;
  top: 0;
  z-index: 2;
  background: linear-gradient(
    to bottom,
    #094890,
    #3996e4,
    #3e99e4d0,
    #3e99e471,
    transparent
  );
  width: 100%;
  padding-bottom: 2rem;
}
.floating-menu .chat .header .title {
  flex-grow: 1;
  flex-shrink: 1;
  padding: 0 5px;
}
.floating-menu .chat .header button {
  flex-shrink: 0;
}
.floating-menu .chat .messages {
  padding: 3rem 10px 0px;
  margin: 0;
  list-style: none;
  overflow-y: scroll;
  overflow-x: hidden;
  flex-grow: 1;
  border-radius: 4px;
  background: transparent;
}
.floating-menu .chat .messages::-webkit-scrollbar {
  width: 5px;
}
.floating-menu .chat .messages::-webkit-scrollbar-track {
  border-radius: 5px;
  background-color: rgba(25, 147, 147, 0.1);
  background-color: transparent;
}
.floating-menu .chat .messages::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: #094890 !important;
  border: none;
}
.floating-menu .chat .messages li {
  position: relative;
  clear: both;
  display: inline-block;
  padding: 14px;
  margin: 0 0 20px 0;
  font: 12px/16px "Noto Sans", sans-serif;
  border-radius: 10px;
  background-color: rgba(246, 248, 250, 0.836);
  word-wrap: break-word;
  max-width: 81%;
  border: none;
}
.floating-menu .chat .messages li:before {
  position: absolute;
  top: 0;
  width: 25px;
  height: 25px;
  border-radius: 25px;
  content: "";
  background-size: cover;
}
.floating-menu .chat .messages li:after {
  position: absolute;
  top: 10px;
  content: "";
  width: 0;
  height: 0;
  border-top: 10px solid rgba(246, 248, 250, 0.836);
  border-right: 1px solid rgba(246, 248, 250, 0.836);
}
.floating-menu .chat .messages li.other {
  animation: show-chat-odd 0.15s 1 ease-in;
  -moz-animation: show-chat-odd 0.15s 1 ease-in;
  -webkit-animation: show-chat-odd 0.15s 1 ease-in;
  float: right;
  margin-right: 45px;
  color: #094890;
}
.floating-menu .chat .messages li.other:before {
  right: -45px;
  background-image: url(https://github.com/Thatkookooguy.png);
}
.floating-menu .chat .messages li.other:after {
  border-right: 10px solid transparent;
  right: -10px;
}
.floating-menu .chat .messages li.self {
  animation: show-chat-even 0.15s 1 ease-in;
  -moz-animation: show-chat-even 0.15s 1 ease-in;
  -webkit-animation: show-chat-even 0.15s 1 ease-in;
  float: left;
  margin-left: 45px;
  color: #094890;
}
.floating-menu .chat .messages li.self:before {
  left: -45px;
  background-image: url(https://github.com/ortichon.png);
}
.floating-menu .chat .messages li.self:after {
  border-left: 10px solid transparent;
  left: -10px;
}
.floating-menu .chat .footer {
  flex-shrink: 0;
  display: flex;
  padding-top: 10px;
  max-height: 90px;
  background: transparent;
}
.floating-menu .chat .footer .text-box {
  border-radius: 3px;
  background: rgba(246, 248, 250, 0.993);
  border: 2px solid #094890;
  min-height: 100%;
  width: 100%;
  margin-right: 5px;
  color: #0ec879;
  overflow-y: auto;
  padding: 2px 5px;
}
.floating-menu .chat .footer .text-box::-webkit-scrollbar {
  width: 5px;
}
.floating-menu .chat .footer .text-box::-webkit-scrollbar-track {
  border-radius: 5px;
  background-color: rgba(25, 147, 147, 0.1);
}
.floating-menu .chat .footer .text-box::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: rgba(25, 147, 147, 0.2);
}
@keyframes show-chat-even {
  0% {
    margin-left: -480px;
  }
  100% {
    margin-left: 0;
  }
}
@-moz-keyframes show-chat-even {
  0% {
    margin-left: -480px;
  }
  100% {
    margin-left: 0;
  }
}
@-webkit-keyframes show-chat-even {
  0% {
    margin-left: -480px;
  }
  100% {
    margin-left: 0;
  }
}
@keyframes show-chat-odd {
  0% {
    margin-right: -480px;
  }
  100% {
    margin-right: 0;
  }
}
@-moz-keyframes show-chat-odd {
  0% {
    margin-right: -480px;
  }
  100% {
    margin-right: 0;
  }
}
@-webkit-keyframes show-chat-odd {
  0% {
    margin-right: -480px;
  }
  100% {
    margin-right: 0;
  }
} */
</style>
